import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TermsofserviceComponent } from './termsofservice/termsofservice.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { UserDeleteRequestComponent } from './user-delete-request/user-delete-request.component';
import { ReasonComponent } from './user-delete-request/components/reason/reason.component';
import { roleGuard } from './shared/guard/role.guard';
import { Role } from './shared/constants/enum';
import { RequestSucessComponent } from './user-delete-request/components/request-sucess/request-sucess.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'auth',
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./auth/auth.module').then((module) => module.AuthModule),
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((module) => module.AdminModule),
    canActivate: [roleGuard],
    data: { roles: [Role.ADMIN] },
  },
  {
    path: 'terms-of-service',
    component: TermsofserviceComponent,
  },
  {
    path: 'privacy-policy',
    component: PrivacypolicyComponent,
  },
  {
    path: 'user-authentication',
    component: UserDeleteRequestComponent,
  },
  {
    path: 'user-delete-request',
    component: ReasonComponent,
    canActivate:[roleGuard],
    data: {roles:[Role.USER]}
  },
  {
    path:'request-sucess',
    component:RequestSucessComponent,
    // canActivate:[roleGuard],
    // data:{roles:[Role.USER]}
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
