import { CanActivateFn, Router } from '@angular/router';
import { GeneralService } from '../services/general.service';
import { inject } from '@angular/core';
import { ProfileService } from '../services/profile.service';
import { first, map } from 'rxjs';
import { Role } from '../constants/enum';

export const roleGuard: CanActivateFn = (route, state) => {
  const general = inject(GeneralService);
  const router = inject(Router);
  const profile = inject(ProfileService);

  return profile.currentUser.pipe(
    first(),
    map((user) => {
      if (route.data['roles'].includes(user?.role)) {
        return true;
      } else {
        if (user?.role == Role.USER) {
          router.navigateByUrl('/user-authentication');
        } else {
          router.navigate(['/auth/login']);
        }
        return false;
      }
    })
  );
};
