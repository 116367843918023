<div class="section-div w-full h-screen px-4">
  <div class="w-full flex flex-col justify-center items-center mt-12 mb-12">
    <h1 class="large-text">BINGO</h1>
    <p class="sub-text">Explore More</p>
  </div>
  <div class="w-full flex flex-col justify-center items-center">
    <h3 class="sub-text pb-6">PRIVACY POLICY</h3>
    <div class="flex flex-col">
      <div class="w-full flex justify-between items-center pb-4">
        <div class="flex flex-col">
          <p class="card-text" style="line-height: 2rem !important">
            BINGO-Privacy policy
          </p>
          <p class="card-text" style="line-height: 2rem !important">
            Effective Date: 30th December 2023
          </p>
        </div>
      </div>
      <p class="extra-small-text" style="line-height: 2rem !important">
        ("we," "our," or "us"). We are committed to protecting your privacy and
        ensuring the security of your personal information. This Privacy Policy
        explains how we collect, use, and disclose information when you use our
        mobile application and related services (collectively, the "App").
      </p>
    </div>
  </div>
</div>
