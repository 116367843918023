<div class="section-div w-full h-screen px-4">
  <div class="w-full flex flex-col justify-center items-center mt-12 mb-12">
    <h1 class="large-text">BINGO</h1>
    <p class="sub-text">Explore More</p>
  </div>
  <div class="w-full flex flex-col justify-center items-center">
    <h3 class="sub-text pb-6">TERMS OF SERVICE</h3>
    <div class="flex flex-col">
      <p class="extra-small-text" style="line-height: 2rem !important">
        Please read the following terms and conditions carefully before using
        our Bingo App ("the App"). By using the App, you agree to be bound by
        these terms and conditions, which constitute a legally binding agreement
        between you and the App's operator ("we", "our", or "us"). If you do not
        agree with these terms and conditions, please refrain from using the
        App.
      </p>
    </div>
  </div>
</div>
