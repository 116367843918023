<div class="header flex justify-end items-center">
  <div class="flex gap-4 pr-24">
    <div>
      <button
        class="flex gap-2 items-center"
        [matMenuTriggerFor]="profile_menu"
      >
        <span class="icon"
          ><img src="../../../../assets/Icons/user.svg"
        /></span>
        <span class="extra-small-text">Admin</span>
      </button>
    </div>
    <mat-menu #profile_menu="matMenu" yPosition="below" class="custom-menu">
      <button
        mat-menu-item
        style="color: var(--dark) !important"
        (click)="changePassword()"
      >
        Change Password
      </button>
      <button
        mat-menu-item
        style="color: var(--dark) !important"
        (click)="logout()"
      >
        Logout
      </button>
    </mat-menu>
    <button
      [ngClass]="{
        'open-menu': is_sidebar_opened,
        'close-menu': !is_sidebar_opened
      }"
    >
      <div class="flex items-center gap-5">
        <button class="menu-icon" *ngIf="menu_toggle_icon" (click)="menu_toggle()">
          <img src="../../../../assets/Icons/menu.svg" alt="" />
        </button>
      </div>
    </button>
  </div>
</div>
