<div class="popup-div">
      <form class="w-full flex flex-col  gap-8" [formGroup]="reset_form">
        <div class="flex flex-col gap-4 mb-8">
          <div class="flex justify-between items-center">
            <div class="flex gap-4 items-center">
              <div class="yellow-bar"></div>
              <h1 class="large-text text-center">Reset Password</h1>
            </div>
            <img src="../../../assets/Icons/close-icon.svg" class="close-icon" (click)="close()" />
        </div>
          <p class="sub-text text-center">Enter new password and confirm</p>
        </div>
        <div class="flex-column">
          <label>New Password </label>
        </div>
        <div class="inputForm">
          <div class="input-icon">
            <img src="../../../../assets/Icons/lock.svg" />
          </div>
          <input type="password" class="input" placeholder="Enter the new password"
            [ngClass]="{ 'form-input-error': f.new_password.errors && submited }" formControlName="new_password" name="new_password" />
        </div>
        <div *ngIf="f.new_password.errors && submited" class="error-validation">
          <p class="error-validation-text">*New password is required.</p>
        </div>
    
        <div class="flex-column">
          <label>Confirm Password </label>
        </div>
        <div class="inputForm">
          <div class="input-icon">
            <img src="../../../../assets/Icons/lock.svg" />
          </div>
          <input [type]="toggle_icon ? 'text' : 'password'"
            [ngClass]="{ 'form-input-error': f.confirm_password.errors && submited }" class="input"
            placeholder="re-enter your Password" formControlName="confirm_password" name="confirm_password" />
          <button class="flex items-center justify-center pr-2" (click)="changePasswordView()">
            <img class="input-icon" *ngIf="toggle_icon" src="../../../../assets/Icons/eye.svg" alt="" />
            <img class="input-icon" *ngIf="!toggle_icon" src="../../../../assets/Icons/eye-off.svg" alt="" />
          </button>
        </div>
        <div *ngIf="f.confirm_password.errors && submited" class="error-validation">
          <p class="error-validation-text">*Confirm password is required</p>
        </div>
        
        <button class="button-submit flex justify-center items-center" (click)="submit()">
          <span *ngIf="!button_loader">Reset Password</span>
          <mat-spinner class="button-loader" *ngIf="button_loader" diameter="20"></mat-spinner>
        </button>
      </form>
</div>
