import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.scss']
})
export class ConfirmationPopupComponent {

  private unsubscribe = new Subject<void>();
  constructor(
    public dialogRef: MatDialogRef<ConfirmationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data:{message:string}
  ) { 
    if(!data?.message){
      this.dialogRef.close();
    }
  }

  ngOnInit(): void {    
  }
  
  onAction(value:string){
    this.dialogRef.close({value});
  }
  
}
