<div class="w-full h-screen flex flex-col items-center justify-center">
  <form class="form" [formGroup]="profile_delete_form">
    <div class="flex flex-col gap-4 mb-8">
      <div class="flex gap-4 items-center">
        <div class="yellow-bar"></div>
        <h1 class="large-text text-center">Bingo</h1>
      </div>
      <p class="sub-text text-center">Profile Delete!</p>
    </div>
    <div class="flex-column">
      <label>Email</label>
    </div>
    <div class="inputForm">
      <div class="input-icon">
        <img src="../../assets/Icons/user-delete.svg" />
      </div>
      <input
        type="text"
        class="input"
        placeholder="Enter your email"
        formControlName="email"
        [ngClass]="{ 'form-input-error': f.email.errors && submited }"
        name="email"
      />
    </div>
    <div *ngIf="f.email.errors && submited" class="error-validation">
      <p class="error-validation-text">*Please insert a valid email.</p>
    </div>
    <div class="flex-column">
      <label>Reason</label>
    </div>
    <div class="inputFormtext w-full">
      <textarea
        formControlName="reason"
        class="text-area w-full"
        style="width: 100% !important"
        name="reason"
        type="text"
        placeholder="Enter your reason"
      ></textarea>
    </div>
    <div *ngIf="f.reason.errors && submited" class="error-validation">
      <p class="error-validation-text">*Please insert a reason.</p>
    </div>
    <div class="w-full flex items-center justify-center mt-12">
      <button
        class="button-submit flex justify-center items-center max-w-sm"
        (click)="submit(profile_delete_form?.value)"
      >
        <span *ngIf="!button_loader">Submit</span>
        <mat-spinner
          diameter="20"
          class="button-loader"
          *ngIf="button_loader"
        ></mat-spinner>
      </button>
    </div>
  </form>
</div>
