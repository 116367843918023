import { Component } from '@angular/core';

@Component({
  selector: 'app-termsofservice',
  templateUrl: './termsofservice.component.html',
  styleUrls: ['./termsofservice.component.scss']
})
export class TermsofserviceComponent {

}
