import { Component } from '@angular/core';
import { ProfileService } from './shared/services/profile.service';
import { GeneralService } from './shared/services/general.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'bingo';
  constructor(
    private profile: ProfileService,
    private general: GeneralService
  ) {
    if (general.getToken) {
      this.profile.getProfileData();
    }
  }
}
