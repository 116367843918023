import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { GeneralService } from '../../services/general.service';
import { Router } from '@angular/router';
import { ProfileService } from '../../services/profile.service';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordComponent } from '../../popups/change-password/change-password.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  screen_width: any;
  menu_toggle_icon: boolean = false;
  profiles: any;
  @Input() is_sidebar_opened: any;
  @Output() sidebar_open_event = new EventEmitter<boolean>();
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screen_width = window.innerWidth;
    if (this.screen_width <= 1024) {
      this.menu_toggle_icon = true;
    } else {
      this.menu_toggle_icon = false;
    }
  }

  constructor(
    private profile: ProfileService,
    // private _dialog: MatDialog,
    private _router: Router,
    private general: GeneralService,
    private _dialog: MatDialog
  ) {
    this.screen_width = window.innerWidth;
    if (this.screen_width <= 1024) {
      this.menu_toggle_icon = true;
    } else {
      this.menu_toggle_icon = false;
    }
  }

  menu_toggle() {
    this.is_sidebar_opened = !this.is_sidebar_opened;
    this.sidebar_open_event.emit(this.is_sidebar_opened);
  }
  logout() {
    this.general.logOut();
  }
  changePassword() {
    let dialogRef = this._dialog.open(ChangePasswordComponent, {
      width: '490px',
      maxHeight: '90vh',
      panelClass: 'side-popup',
    });
  }
}
