import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { ApiService } from '../../services/api.service';
import { api_constants } from '../../constants/api-constants';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {

  private unsubscribe = new Subject<void>();
  reset_form: any = FormGroup;
  toggle_icon: boolean = false;
  button_loader: boolean = false;
  submited: boolean = false;

  constructor(
    private toastrService: ToastrService,
    private _form_builder: FormBuilder,
    private api: ApiService,
    private _router: Router,
    private _dialog:MatDialog,
    public _dialogRef: MatDialogRef<ResetPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { verification_data: any },
  ) {
    this.reset_form = this._form_builder.group({
      new_password: [ null,Validators.required],
      confirm_password: [ null,Validators.required],
    });
  }

  get f() {
    return this.reset_form.controls;
  }

  changePasswordView() {
    this.toggle_icon = !this.toggle_icon;
  }

  close() {
    this._dialogRef.close('yes')
  }

  submit() {
      this.submited = true;
      let payload = {
        "email":this.data?.verification_data?.email,
        "otp":this.data?.verification_data?.otp,
        "password":this?.reset_form?.value?.new_password,
        "confirm_password":this?.reset_form?.value?.confirm_password,
      }
      if(!payload?.password || !payload?.confirm_password){
        this.toastrService.warning('Password is required', 'Warning', {
          closeButton: true,
        });
      }else{  
        let $this = this;
        this.api.ExecutePut(this.api.baseUrl + api_constants.reset_password, payload)
        .pipe(takeUntil(this.unsubscribe)).subscribe({
          next(value) {
            if (value) {
              $this.close();
              $this._router.navigate([('/auth/login')])
              $this.toastrService.success('Password Changed Successfully', 'Success', {
                closeButton: true,
              });
            }
          }, error(err) {
            $this.toastrService.error(err?.error?.message)
          },
        })
      }
  }
}
