<div class="w-full h-screen flex items-center justify-center flex-col">
  <div class="form flex flex-col">
    <div class="flex gap-4 items-center mb-8">
      <div class="yellow-bar"></div>
      <h1 class="large-text text-center">Bingo</h1>
    </div>
    <div class="w-full flex flex-col justify-center items-center gap-8">
      <h2 class="large-text text-center" style="line-height: 2rem !important">
        Delete request successfully submitted
      </h2>
      <p class="extra-small-text text-center">
        Admin will process your request soon.
      </p>
      <img class="succes-icon" src="../../../../assets/Icons/request.svg" />
    </div>
  </div>
</div>
