import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  constructor(
    private router:Router
  ) { }
  set setToken(token: any) {
    localStorage.setItem('bingo_token', token)
  }

  get getToken() {
    if (localStorage.getItem('bingo_token')) {
      return localStorage.getItem('bingo_token')
    }
    else {
      return null
    }
  }

  logOut() {
    localStorage.clear();
    this.router.navigate(['/auth'])
  }

  bodyRequiredValidator = (body: any, fields: string[]): object | undefined => {
    let required: string[] = []
    fields.forEach((key) => {
        if ([undefined, '', null].includes(body[key])) {
            required.push(key)
        }
    })
    return required.length ? { "missing": required } : undefined
}
}
