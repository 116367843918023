import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, filter } from 'rxjs';
import { api_constants } from '../constants/api-constants';
import { Role } from '../constants/enum';


@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  profileData: BehaviorSubject<any> = new BehaviorSubject(null);
  currentUser: Observable<any> = this.profileData.asObservable().pipe(
    filter(value => Boolean(value))
  );
  constructor(private api: ApiService, private router: Router) {}

  getProfileData(isRedirect?: boolean) {
    let $this = this;
    this.api
      .ExecuteGet(this.api.baseUrl + api_constants.profileDetail)
      .subscribe({
        next(response: any) {
          $this.handleResponse(isRedirect, response);
        },
        error(err) {
          console.log('error', err);
        },
      });
  }
  handleResponse(isRedirect: any, response: any) {
    this.profileData.next(response);
    if (isRedirect) {
      this.redirectTo(response);
    }
  }
  redirectTo(user: any) {
    if (user.role === Role.ADMIN) {
      this.router.navigate(['admin']);
    }else{
      this.router.navigateByUrl('/user-delete-request')
    }
  }

  getUserProfile(isRedirect?: boolean){
    let $this = this;
    this.api
      .ExecuteGet(this.api.baseUrl + api_constants.user_profile)
      .subscribe({
        next(response: any) {
          console.log("user",response)
          $this.handleResponse(isRedirect, response);
        },
        error(err) {
          console.log('error', err);
        },
      });
  }


}
