import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  @Input() is_sidebar_opened: any;
  @Output() sidebar_open_event = new EventEmitter<boolean>();
  scrn_width: any;
  hidden: boolean = false;
  @HostListener('window;resize', ['$event'])
  getScreenSize(event?: any) {
    this.scrn_width = window.innerWidth;
    if (this.scrn_width < 1024) this.is_sidebar_opened = false;
    else this.is_sidebar_opened = true;
  }
  constructor() {
    if (window.innerWidth < 1024) {
      this.is_sidebar_opened = false;
    } else {
      this.is_sidebar_opened = true;
    }
  }
  ngOnInit(): void {}
  menu_toggle() {
    this.is_sidebar_opened = !this.is_sidebar_opened;
    this.sidebar_open_event.emit(this.is_sidebar_opened);
  }
  closeSidebar() {
    if (window.innerWidth < 330) {
      this.is_sidebar_opened = !this.is_sidebar_opened;
      this.sidebar_open_event.emit(this.is_sidebar_opened);
    } else {
      this.is_sidebar_opened = false;
    }
  }
}
