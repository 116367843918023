import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleMapsModule } from '@angular/google-maps';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ResetPasswordComponent } from './popups/reset-password/reset-password.component';
import { MatMenuModule } from '@angular/material/menu';
import { ConfirmationPopupComponent } from './popups/confirmation-popup/confirmation-popup.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ChangePasswordComponent } from './popups/change-password/change-password.component';
import { DeleteConfirmationPopupComponent } from './popups/delete-confirmation-popup/delete-confirmation-popup.component';
import {MatSelectModule} from '@angular/material/select';
import {MatRadioModule} from '@angular/material/radio';
import { CheckinPopupComponent } from './popups/checkin-popup/checkin-popup.component';
import {MatCheckboxModule} from '@angular/material/checkbox';

@NgModule({
  declarations: [
    SidebarComponent,
    NavbarComponent,
    ResetPasswordComponent,
    ConfirmationPopupComponent,
    LoaderComponent,
    ChangePasswordComponent,
    DeleteConfirmationPopupComponent,
    CheckinPopupComponent,
  ],

  imports: [
    CommonModule,
    GoogleMapsModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatMenuModule,
    MatDialogModule,
    MatSelectModule,
    MatRadioModule,
    MatCheckboxModule
  ],
  exports: [
    GoogleMapsModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    MatProgressSpinnerModule,
    SidebarComponent,
    NavbarComponent,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatMenuModule,
    MatDialogModule,
    LoaderComponent,
    MatSelectModule,
    MatRadioModule,
    MatCheckboxModule
  ],
  providers: [],
})
export class SharedModule {}
