export const api_constants = Object.freeze({
  login: 'admin/login',
  profileDetail: 'admin/profile',
  generate_otp: 'auth/generate-otp',
  otp_verification: 'auth/otp-verification',
  reset_password: 'auth/reset-password',
  list_bingo_card: 'admin/bingo-cards',
  enable_disable_bingo_card: 'admin/enable-disable-card',
  create_bingo_loaction: 'admin/bingo-location',
  delete_location: 'admin/location',
  user_list: 'admin/users',
  user_detail: 'admin/user',
  enable_disable_user: 'admin/enable-disable-user',
  user_card_detail: 'admin/user-cards',
  top_user: 'admin/top-users',
  user_reports: 'admin/user-reports',
  card_purchase_report: 'admin/card-purchase-reports',
  enable_disable_location: 'admin/enable-disable-location',
  preurlrequest: 'auth/pre-url-request',
  profile_delete_request: 'auth/delete-request',
  change_password: 'auth/change-password',
  bingo_card_detail:'admin/bingo-card',
  update_location:'admin/location',
  location_card_detail:'admin/location-detail',
  sequence_list:'admin/sequences',
  remove_bingo_card:'admin/remove-card',
  delete_request:'admin/delete-requests',
  user_login:'user/login',
  reason:'auth/delete-request',
  status_request:'admin/delete-request',
  user_profile:'user/user-profile',
  push_notification:'admin/push-notification',
  checkin_list:'admin/checkin-requests',
  checkin_deatil:'admin/checkin-request'
});
