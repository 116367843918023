import { Component } from '@angular/core';

@Component({
  selector: 'app-request-sucess',
  templateUrl: './request-sucess.component.html',
  styleUrls: ['./request-sucess.component.scss']
})
export class RequestSucessComponent {

}
