<div class="w-full h-screen flex flex-col items-center justify-center">
    <form class="form" [formGroup]="user_delete_form">
        <div class="flex flex-col gap-4 mb-8">
            <div class="flex gap-4 items-center">
              <div class="yellow-bar"></div>
              <h1 class="large-text text-center">Bingo</h1>
            </div>
            <p class="sub-text text-center">Login with your email & password.<br>delete your account</p>
          </div>
          <div class="flex-column">
            <label>Email </label>
          </div>
          <div class="inputForm">
            <div class="input-icon">
              <img src="../../../../assets/Icons/at-sign.svg" />
            </div>
            <input type="text" class="input" placeholder="Enter your Email"
              [ngClass]="{ 'form-input-error': f.email.errors && submited }" formControlName="email" name="email" />
          </div>
          <div *ngIf="f.email.errors && submited" class="error-validation">
            <p class="error-validation-text">*Please insert a valid email address.</p>
          </div>
          <div class="flex-column">
            <label>Password </label>
          </div>
          <div class="inputForm">
            <div class="input-icon">
              <img src="../../../../assets/Icons/lock.svg" />
            </div>
            <input [type]="toggle_icon ? 'text' : 'password'"
              [ngClass]="{ 'form-input-error': f.password.errors && submited }" class="input"
              placeholder="Enter your Password" formControlName="password" name="password" />
            <button class="flex items-center justify-center pr-2" (click)="changePasswordView()">
              <img class="input-icon" *ngIf="toggle_icon" src="../../../../assets/Icons/eye.svg" alt="" />
              <img class="input-icon" *ngIf="!toggle_icon" src="../../../../assets/Icons/eye-off.svg" alt="" />
            </button>
          </div>
          <div *ngIf="f.password.errors && submited" class="error-validation">
            <p class="error-validation-text">*Password is required</p>
          </div>
          <button class="button-submit flex justify-center items-center" (click)="submit(user_delete_form?.value)">
            <span *ngIf="!button_loader">Sign in</span>
            <mat-spinner class="button-loader" *ngIf="button_loader" diameter="20"></mat-spinner>
          </button>

          <!-- <div class="flex flex-col w-full gap-1 justify-center items-center">
            <label class="form-label">or</label>
            <div class=" w-full flex items-center justify-between gap-6" >
                <button class="only-icon-btn w-full flex items-center justify-center gap-4" [disabled]="button_loader">
                    <img class="sign-in-with-icon" src="../../assets/Icons/google.png" alt=""> <span>Sign in with Google</span>
                  </button>
                  <button class="only-icon-btn flex justify-center items-center" [disabled]="button_loader" >
                    <img class="sign-in-with-icon" src="/assets/icons/microsoft.svg" alt="">
                  </button>
            </div>
        </div> -->
    </form>
</div>