import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { api_constants } from 'src/app/shared/constants/api-constants';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-reason',
  templateUrl: './reason.component.html',
  styleUrls: ['./reason.component.scss'],
})
export class ReasonComponent {
  private unsubscribe = new Subject<void>();
  profile_delete_form: any = FormGroup;
  toggle_icon: boolean = false;
  button_loader: boolean = false;
  submited: boolean = false;
  delete_status: boolean = false;
  token_id: any;
  constructor(
    private toastrService: ToastrService,
    private _form_builder: FormBuilder,
    private api: ApiService,
    private _router: Router
  ) {
    if (localStorage.getItem('bingo_user_token')) {
      this.token_id = localStorage.getItem('bingo_user_token');
    }
    console.log('value', this.token_id);
    this.profile_delete_form = this._form_builder.group({
      email: [null, Validators.required],
      reason: [null,Validators.required],
    });
  }

  get f() {
    return this.profile_delete_form.controls;
  }

  submit(data: any) {
    this.submited = true;
    if (this.profile_delete_form.invalid) {
      this.toastrService.warning(
        'Email & Reason is required',
        'Warning',
        {
          closeButton: true,
        }
      );
    } else {
      this.deleteProfile(data);
    }
  }

  deleteProfile(data: any) {
    let $this = this;
    this.api
      .ExecutePost(this.api.baseUrl + api_constants.reason, data)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next(value: any) {
          $this.toastrService.success('Request is succesfully submitted');
          if(value){
            $this._router.navigateByUrl('/request-sucess');
          }
        },
        error(err: any) {
          console.log('error:', err);
          $this.toastrService.error(err?.error?.message);
        },
      });
  }
}
