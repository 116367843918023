export enum S3UploadType {
  PROFILEPIC = 'profile_pic',
  IMAGES = 'images',
}
export enum DeleteRequestStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export enum CheckinRequestStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export enum Role {
  USER = 'user',
  ADMIN = 'admin',
}

export enum INotificationType {
  CITY = 'city',
  USER_SPECIFIC = 'user_specific',
  ZIP_CODE = 'zip_code',
}

export enum EMapAddressFormat {
  LOCALITY = 'locality',
  STATE = 'administrative_area_level_1',
}
