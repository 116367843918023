import { Component, Inject } from '@angular/core';

import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ApiService } from '../../services/api.service';
import { ToastrService } from 'ngx-toastr';
import { api_constants } from '../../constants/api-constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent {
  private unsubscribe = new Subject<void>();
  change_password_form: any = FormGroup;
  toggle_icon: boolean = false;
  button_loader: boolean = false;
  submited: boolean = false;
  constructor(
    private toastrService: ToastrService,
    private _form_builder: FormBuilder,
    private api: ApiService,
    private _router: Router,
    private _dialog: MatDialog,
    public _dialogRef: MatDialogRef<ChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { verification_data: any }
  ) {
    this.change_password_form = this._form_builder.group({
      old_password: [null, Validators.required],
      new_password: [null, Validators.required],
      confirm_password: [null, Validators.required],
    });
  }
  ngOnInit(): void {}

  get f() {
    return this.change_password_form.controls;
  }

  changePasswordView() {
    this.toggle_icon = !this.toggle_icon;
  }

  close() {
    this._dialogRef.close();
  }

  submit(data: any) {
    this.submited = true;
    if (this.change_password_form.invalid) {
      this.toastrService.warning('Password is required', 'Warning', {
        closeButton: true,
      });
    } else {
      this.resetPassword(data);
    }
  }
  resetPassword(data: any) {
    let $this = this;
    let forgot_value = this.change_password_form?.value;
    this.api
      .ExecutePost(
        this.api.baseUrl + api_constants.change_password,
        forgot_value
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next(value) {
          $this.handleResponce(value);
        },
        error(err) {
          $this.handleError(err);
        },
      });
  }
  handleResponce(value: any) {
    this.toastrService.success(
      'Password changed succesfully',
      'Password Changed',
      {
        closeButton: true,
      }
    );
    this._router.navigate(['auth/login']);
    this._dialogRef.close(true);
  }
  handleError(value: any) {
    this.toastrService.error(value?.error?.message, 'Failed', {
      closeButton: true,
    });
  }
}
