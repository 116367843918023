import { HttpParams } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { DeleteRequestStatus } from '../../constants/enum';
import { ApiService } from '../../services/api.service';
import { ToastrService } from 'ngx-toastr';
import { api_constants } from '../../constants/api-constants';
import { query } from '@angular/animations';

@Component({
  selector: 'app-delete-confirmation-popup',
  templateUrl: './delete-confirmation-popup.component.html',
  styleUrls: ['./delete-confirmation-popup.component.scss'],
})
export class DeleteConfirmationPopupComponent {
  private unsubscribe = new Subject<void>();
  request_data: any;
  delete_request_enum = DeleteRequestStatus;

  constructor(
    public dialogRef: MatDialogRef<DeleteConfirmationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api: ApiService,
    private toast: ToastrService
  ) {
    this.request_data = data;
  }
  ngOnInit(): void {}

  onApprove() {
    console.log('data', this.request_data);
    let query = new HttpParams();
    query = query.set('status', this.delete_request_enum.APPROVED);
    let $this = this;
    this.api
      .ExecutePut(
        `${this.api?.baseUrl + api_constants?.status_request}/${
          this.request_data?.item?._id
        }`,
        '',
        '',
        query
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next(value) {
          $this.toast.success(
            'User delete request approved',
            'Approved Sucessfully',
            {
              closeButton: true,
            }
          );
          $this.closeModal(true);
        },
        error(err) {
          console.log('error:', err);
        },
      });
  }

  closeModal(status: boolean) {
    this.dialogRef.close(status);
  }

  onReject() {
    console.log('data', this.request_data);
    let query = new HttpParams();
    query = query.set('status', this.delete_request_enum.REJECTED);
    let $this = this;
    this.api
      .ExecutePut(
        `${this.api?.baseUrl + api_constants?.status_request}/${
          this.request_data?.item?._id
        }`,
        '',
        '',
        query
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next(value) {
          $this.toast.success(
            'User delete request rejected',
            'Rejected Sucessfully',
            {
              closeButton: true,
            }
          );
          $this.closeModal(true);
        },
        error(err) {
          console.log('error:', err);
        },
      });
  }
}
