import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { GeneralService } from '../services/general.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private general:GeneralService) {}

  
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if(this.general.getToken && !request.url.includes('storage.googleapis.com')){
      const modified = request.clone({
        setHeaders:{Authorization:this.general.getToken}
      })
      return next.handle(modified);

    }else{
      return next.handle(request);
    }
   
  }

}
