import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { ApiService } from '../shared/services/api.service';
import { GeneralService } from '../shared/services/general.service';
import { ProfileService } from '../shared/services/profile.service';
import { Router } from '@angular/router';
import { api_constants } from '../shared/constants/api-constants';

@Component({
  selector: 'app-user-delete-request',
  templateUrl: './user-delete-request.component.html',
  styleUrls: ['./user-delete-request.component.scss'],
})
export class UserDeleteRequestComponent {
  private unsubscribe = new Subject<void>();
  user_delete_form: any = FormGroup;
  toggle_icon: boolean = false;
  button_loader: boolean = false;
  submited: boolean = false;
  token_id: any;

  constructor(
    private toastrService: ToastrService,
    private _form_builder: FormBuilder,
    private api: ApiService,
    private general: GeneralService,
    private profile: ProfileService,
    private _router: Router
  ) {
    this.user_delete_form = this._form_builder.group({
      email: [
        null,
        Validators.compose([Validators.required, Validators.email]),
      ],
      password: [null, Validators.required],
    });
  }

  get f() {
    return this.user_delete_form.controls;
  }

  changePasswordView() {
    this.toggle_icon = !this.toggle_icon;
  }

  submit(data: any) {
    this.submited = true;
    if (this.user_delete_form.invalid) {
      this.toastrService.warning('Username & Password is required', 'Warning', {
        closeButton: true,
      });
    } else {
      this.form_api(data);
    }
  }

  form_api(form_value: any) {
    this.button_loader = true;
    let $this = this;
    this.api
      .ExecutePost(this.api.baseUrl + api_constants.user_login, form_value)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next(value: any) {
          console.log('user', value);
          $this.general.setToken = value.token;
          $this.profile.getUserProfile(true);
          $this.toastrService.success('Welcome to Bingo', 'Login Sucess', {
            closeButton: true,
          });
        },
        error(err) {
          $this.toastrService.error(err?.error?.message, 'Login Failed', {
            closeButton: true,
          });
          $this.button_loader = false;
        },
      });
  }



}
