<div class="popup-div">
  <form class="w-full flex flex-col gap-4" [formGroup]="change_password_form">
    <div class="flex flex-col gap-4 mb-8">
      <div class="flex justify-between items-center">
        <div class="flex gap-4 items-center">
          <div class="yellow-bar"></div>
          <h1 class="large-text text-center">Change Password</h1>
        </div>
        <img
          src="../../../assets/Icons/close-icon.svg"
          class="close-icon"
          (click)="close()"
        />
      </div>
      <p class="sub-text text-center">Enter a new password and confirm</p>
    </div>
    <div class="flex-column">
      <label>Old Password </label>
    </div>
    <div class="inputForm">
      <div class="input-icon">
        <img src="../../../../assets/Icons/lock.svg" />
      </div>
      <input
        type="password"
        class="input"
        placeholder="Enter the old password"
        formControlName="old_password"
        name="old_password"
      />
    </div>
    <div *ngIf="f.old_password.errors && submited" class="error-validation">
      <p class="error-validation-text">*Old password is required.</p>
    </div>
    <div class="flex-column">
      <label>New Password </label>
    </div>
    <div class="inputForm">
      <div class="input-icon">
        <img src="../../../../assets/Icons/lock.svg" />
      </div>
      <input
        type="password"
        class="input"
        placeholder="Enter the new password"
        formControlName="new_password"
        name="new_password"
      />
    </div>
    <div *ngIf="f.new_password.errors && submited" class="error-validation">
      <p class="error-validation-text">*New password is required.</p>
    </div>

    <div class="flex-column">
      <label>confirm password </label>
    </div>
    <div class="inputForm">
      <div class="input-icon">
        <img src="../../../../assets/Icons/lock.svg" />
      </div>
      <input
        [type]="toggle_icon ? 'text' : 'password'"
        class="input"
        placeholder="Re-enter the new password"
        formControlName="confirm_password"
        name="confirm_password"
      />
      <button
        class="flex items-center justify-center pr-2"
        (click)="changePasswordView()"
      >
        <img
          class="input-icon"
          *ngIf="toggle_icon"
          src="../../../../assets/Icons/eye.svg"
          alt=""
        />
        <img
          class="input-icon"
          *ngIf="!toggle_icon"
          src="../../../../assets/Icons/eye-off.svg"
          alt=""
        />
      </button>
    </div>
    <div *ngIf="f.confirm_password.errors && submited" class="error-validation">
      <p class="error-validation-text">*Confirm password is required</p>
    </div>

    <button class="button-submit flex justify-center items-center" (click)="submit(change_password_form?.value)">
      <span>Change Password</span>
    </button>
  </form>
</div>
